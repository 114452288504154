let $filters = document.querySelectorAll('.filter')
let $filterChips = document.querySelector('.filter-chips__container');


let filterHideClass = 'filter--hide';
let filterExpandClass = 'filter--expand';
let filterFocusClass = 'filter--focus';
let filterOptionActiveClass = 'filter__body-container-option--active';
let filterCheckboxActiveClass = 'filter__checkbox-option--active';
let filterCheckboxWrapperActiveClass = 'filter__body-container-wrapper--active';
let specialistCardHideClass = 'specialist-new__container-card--hide';

let $specialistList = document.querySelectorAll('.specialist-new__container-card')
let $filterBtns = document.querySelectorAll('.filter-btn');
let $filtersCloseBtn = document.querySelectorAll('.filter__body-container-m-close')

let filterData = {
    tags: {},
    sex: null,
    text: null,
    directions: null,
    datetime: null
}

let day = 0;

document.addEventListener("triggerFilterData", function (e) {
    $params = e.detail.params;
    triggerFilterRequest($params);
});

$filters.forEach($filter => {
    initFilter($filter);
})
$filterBtns.forEach($btn => {
    $btn.addEventListener('click', e => {
        filterTrigger();
        $filters.forEach($filter => {
            $filter.classList.remove(filterExpandClass);
        })
    })
})
$filtersCloseBtn.forEach($btn => {
    $btn.addEventListener('click', e => {
        $filters.forEach($filter => {
            $filter.classList.remove(filterExpandClass);
        })
    })
})
document.addEventListener('click', e => {
    $filters.forEach($filter => {
        $filter.classList.remove(filterExpandClass);
    })
})


function initFilter($filter) {
    let filterType = $filter.dataset.type;

    if (filterType === 'select') {
        let $filterBody = $filter.querySelector('.filter__body')
        let $filterContainer = $filter.querySelector('.filter__body-container')
        let $filterOptions = $filterContainer.querySelectorAll('.filter__body-container-option')

        $filterBody.addEventListener('click', e => {
            changeFilterExpand($filter)
        })

        $filterOptions.forEach($filterOption => {
            $filterOption.addEventListener('click', e => {
                let $option = e.currentTarget;
                let head = $filter.dataset.head;

                getCurrentFilterOption($filter, $option)
                refreshFocus($filterOptions, $option, filterOptionActiveClass)
                changeFilterExpand($filter)

                filterData[head] = {
                    'value': $option.dataset.value,
                    'name': $option.innerHTML
                };
                filterTrigger();
            })
        })

    }

    if (filterType === 'checkbox') {
        let $filterBody = $filter.querySelector('.filter__checkbox')
        let $filterOptions = $filterBody.querySelectorAll('.filter__checkbox-option')

        $filterBody.addEventListener('click', e => {
            switchOption($filterBody, $filterOptions)
            filterTrigger();
        })
    }

    if (filterType === 'hider') {
        let hiderNumber = $filter.dataset.switch_hider;
        let $hiderFilters = document.querySelectorAll('.filter[data-hider="' + hiderNumber + '"]')

        switchHider($filter, $hiderFilters)
        $filter.addEventListener('click', e => {
            switchHider($filter, $hiderFilters)
            changeFilterFocus($filter);
        })

    }

    if (filterType === 'wrap') {
        let $filterBody = $filter.querySelector('.filter__body')
        let $filterContainer = $filter.querySelector('.filter__body-container')

        let $filterWrappers = $filter.querySelectorAll('.filter__body-container-wrapper');
        let $filterWrappers_items = $filter.querySelectorAll('.filter__body-container-checkbox');


        if ($filter.dataset.head == 'tags') {
            $filterWrappers_items.forEach($item => {
                $item.addEventListener('click', e => {
                    e.preventDefault();
                    checkboxSwitch($item);
                    //filterTrigger();

                })
            })
        } else {
            $filterWrappers_items.forEach($item => {
                $item.addEventListener('click', e => {
                    e.preventDefault();
                    checkboxCustomSwitch($item);
                    //filterTrigger();

                })
            })
        }


        $filterWrappers.forEach($wrapper => {
            $wrapTitle = $wrapper.querySelector('.filter__body-container-wrapper-title')
            $wrapTitle.addEventListener('click', e => {

                changeFilterExpand($wrapper, filterCheckboxWrapperActiveClass, false, $filterContainer)

            })
        })

        $filterBody.addEventListener('click', e => {
            changeFilterExpand($filter)
        })
    }

    if (filterType === 'calendar') {
        let $filterBody = $filter.querySelector('.filter__body')
        let $filterContainer = $filter.querySelector('.filter__body-container')
        let $calendar = $filter.querySelector('.calendar')
        let $days = $filter.querySelectorAll('.calendar tbody td.container')
        console.log('DAYS')
        console.log($days)

        document.addEventListener("refreshDate", () => { // (1)
            filterData.datetime = $calendar.dataset.day + ' ' + $calendar.dataset.time
            //chipsData.datetime = $calendar.dataset.day + ' ' + $calendar.dataset.time;
            filterTrigger();
            $filters.forEach($filter => {
                $filter.classList.remove(filterExpandClass);
            })
        });

        $filterBody.addEventListener('click', e => {
            changeFilterExpand($filter)
            let event = new CustomEvent("showCalendar");
            document.dispatchEvent(event);
        })
    }

    if (filterType == 'datepicker'){

        let $filterBody = $filter.querySelector('.filter__body')
        let $filterContainer = $filter.querySelector('.filter__body-container')
        let $daypicker = $filter.querySelector('.daypicker')

        $filterBody.addEventListener('click', e => {
            changeFilterExpand($filter)
        })

        document.addEventListener("refreshDatepicker", () => {
            console.log('refresh DP')
            filterData.datestart = $daypicker.dataset.first
            filterData.dateend = $daypicker.dataset.second
            filterTrigger();
            $filters.forEach($filter => {
                $filter.classList.remove(filterExpandClass);
            })
        });
    }

    if (filterType == 'search') {
        let $search = $filter.querySelector('.filter__search');
        let $searchInput = $filter.querySelector('input');
        let $searchBtn = $filter.querySelector('.filter__search-btn')

        $searchInput.onfocus = function () {
            $searchBtn.classList.add('filter__search-btn--active')
        }
        // $searchInput.onblur = function (e){
        //     console.log(e.target)
        //     $searchBtn.classList.remove('filter__search-btn--active')
        // }
        $searchBtn.addEventListener('click', e => {
            filterData.text = $searchInput.value;
            //chipsData.text = 'Поиск: ' + $searchInput.value;
            filterTrigger();
        })


        document.addEventListener("filterSearchPicked", (e) => {
            console.log('filterSearchPicked');
            console.log(e.detail);

            if (e.detail.head == 'tags'){
                filterData.tags[e.detail.value.toString()] = e.detail.name;
            }else {
                filterData[e.detail.head] = {
                    'value': e.detail.value,
                    'name': e.detail.name
                };
            }


            filterTrigger();
            //bankCardData = e.detail.bankCardData
        });

    }

    $filter.addEventListener('click', e => {
        //console.log($filter);
        if ($filter.dataset.type != 'search')
        e.stopPropagation();
    })
}


function triggerFilterRequest($data){
    console.log('TRIGGERED DATA')
    console.log($data);
    if ($data){
        for (const [key, value] of Object.entries($data)) {
            if (key == 'tags')
            {
                filterData.tags[value['id']] = value['name'];
            }
        }
        filterTrigger();
    }

}
function checkboxSwitch($checkbox) {
    // let index = filterData.tags.indexOf($checkbox.dataset.value);
    let input = $checkbox.querySelector('input');

    if ($checkbox.dataset.active == '0' && !($checkbox.dataset.value in filterData.tags)) {
        input.checked = true;
        $checkbox.dataset.active = '1'
        filterData.tags[$checkbox.dataset.value.toString()] = $checkbox.dataset.title;
        //chipsData.tags.push($checkbox.dataset.title)
    } else if ($checkbox.dataset.active == '1' && ($checkbox.dataset.value in filterData.tags)) {
        input.checked = false;
        $checkbox.dataset.active = '0'
        delete filterData.tags[$checkbox.dataset.value];
        //chipsData.tags.splice(index, 1)
    }
}

function checkboxCustomSwitch($checkbox) {
    console.log($checkbox)
    let input = $checkbox.querySelector('input');

    if ($checkbox.dataset.active == '0') {
        let $otherChecks = document.querySelectorAll('.filter-item[data-head="' + $checkbox.dataset.head + '"]');
        $otherChecks.forEach($check => {
            let checkInput = $check.querySelector('input')
            checkInput.checked = false;
            $check.dataset.active = '0'
        })

        input.checked = true;
        $checkbox.dataset.active = '1'

        filterData[$checkbox.dataset.head] = {
            'value': $checkbox.dataset.value,
            'name': $checkbox.dataset.title
        };

    } else if ($checkbox.dataset.active == '1') {
        input.checked = false;
        $checkbox.dataset.active = '0'
        delete filterData[$checkbox.dataset.head]
    }

}

function changeFilterExpand($filterBody, expandClass = filterExpandClass, fullWatch = true, $upContainer = null) {

    if ($filterBody.classList.contains(expandClass)) {
        $filterBody.classList.remove(expandClass)

        if ($upContainer) {
            $upContainer.classList.remove('filter__body-container--up')
        }
    } else {
        if (fullWatch) {
            $filters.forEach($filter => {
                $filter.classList.remove(filterExpandClass);
            })
        }


        $filterBody.classList.add(expandClass)
        if ($upContainer) {
            $upContainer.classList.add('filter__body-container--up')
        }
    }
}

function changeFilterFocus($filterBody, expandClass = filterFocusClass) {

    if ($filterBody.classList.contains(expandClass)) {
        $filterBody.classList.remove(expandClass)
    } else {
        $filterBody.classList.add(expandClass)
    }
}

function getCurrentFilterOption($filterBody, $option) {
    $filterBody.dataset.value = $option.dataset.value;
}

function refreshFocus($elements, $focus = null, focusClass = null) {
    $elements.forEach($containerFocusElement => {
        $containerFocusElement.classList.remove(focusClass);
    })

    if ($focus)
        $focus.classList.add(focusClass);

}

function switchOption($filterBody, $filterOptions) {
    $filterOptions.forEach($option => {
        if ($option.classList.contains(filterCheckboxActiveClass)) {
            $option.classList.remove(filterCheckboxActiveClass);
            filterData.visit = 1;
            //chipsData.visit = 'Онлайн'
        } else {
            $filterBody.dataset.value = $option.dataset.value;
            $option.classList.add(filterCheckboxActiveClass);
            filterData.visit = 0;
            //chipsData.visit = 'Очно'
        }
    })
}

function switchHider($hider, $hiderFilters) {


    if ($hider.dataset.value == 1) {
        $hiderFilters.forEach($hiderFilter => {
            $hiderFilter.classList.remove(filterHideClass)
        })
        $hider.dataset.value = 0;
    } else {
        $hiderFilters.forEach($hiderFilter => {
            $hiderFilter.classList.add(filterHideClass)
        })
        $hider.dataset.value = 1;
    }
}

function refreshSpecialists(showIds) {
    $specialistList.forEach($specCard => {
        if (showIds.includes(parseInt($specCard.dataset.specialist))) {
            $specCard.classList.remove(specialistCardHideClass);
        } else {
            $specCard.classList.add(specialistCardHideClass);
        }
    })
}

function filterTrigger() {
    console.log(filterData);
    refreshChips(filterData)

    let url = '/specialists/get'
    axios.get(url, {
        params: filterData
    }).then((response) => {
        console.log(response.data.data);
        let specIds = []
        response.data.data.forEach(spec => {
            specIds.push(spec.id)
        })
        refreshSpecialists(specIds);
    })
        .catch((error) => {
            console.log(error)
        });

}

function refreshChips(filterData) {

    let chipsDom = ''
    let filterCount = 0;

    for (const [key, value] of Object.entries(filterData)) {
        if (typeof value === 'object' && value !== null) {
            if (key == 'tags') {
                for (const [valueKey, valueValue] of Object.entries(value)) {
                    chipsDom += addChipsDom(valueValue, 'tags', valueKey)
                    filterCount++;
                }
            } else {
                chipsDom += addChipsDom(value['name'], key, value['value'])
                filterCount++;
            }

        } else if (key == 'visit') {
            if (value == 0) {
                chipsDom += addChipsDom('Очно', 'visit', null)
                filterCount++;
            }
            if (value == 1) {
                chipsDom += addChipsDom('Онлайн', 'visit', null)
                filterCount++;
            }
        } else {
            if (value != null) {
                chipsDom += addChipsDom(value, key, null)
                filterCount++;
            }

        }
    }

    if (filterCount > 0) {
        chipsDom = '<div class="filter-chips__item filter-chips__item--fade">\n' +
            '                        <div class="filter-chips__item-text">Очистить всё</div>\n' +
            '                        <div  data-id="null" data-key="all" class="filter-chips__item-icon">\n' +
            '                            <svg>\n' +
            '                                <use xlink:href="/icons/icons-sprite.svg#close"></use>\n' +
            '                            </svg>\n' +
            '                        </div>\n' +
            '                    </div>' + chipsDom
    }

    $filterChips.innerHTML = chipsDom;
    refreshChipsAction();

}

function refreshChipsAction() {
    let $chipsItems = document.querySelectorAll('.filter-chips__item');
    $chipsItems.forEach($item => {
        let $itemBtn = $item.querySelector('.filter-chips__item-icon')
        $itemBtn.addEventListener('click', e => {
            console.log('chipsClick');
            console.log(filterData)
            removeChipsFilter($item, $itemBtn);
            filterTrigger();
        });
    })
}

function removeChipsFilter($item, $itemBtn, checks = true) {
    let id = $itemBtn.dataset.id;
    let key = $itemBtn.dataset.key;

    console.log('removeChipsFilter')
    console.log(filterData)
    unmarkFilterItem(key, id)

    if (checks) {
        if (key == 'all') {
            $item.remove();
            let $chipsItems = document.querySelectorAll('.filter-chips__item');
            $chipsItems.forEach($innerItem => {
                let $innerItemBtn = $innerItem.querySelector('.filter-chips__item-icon')
                removeChipsFilter($innerItem, $innerItemBtn);
            })
            return
        } else if (key == 'tags') {
            delete filterData.tags[id];
        } else {
            delete filterData[key];
        }
    } else {
        delete filterData[key];
    }

    $item.remove();
}

function unmarkFilterItem(filter, value) {
    console.log('unmarkFilterItem')
    console.log(filter)
    console.log(value)
    console.log(filterData)

    //filter-item
    let $container = document.querySelector('.filter[data-head="' + filter + '"]')
    if ($container) {
        if (filter == 'tags') {
            let $container = document.querySelector('.filter[data-head="tags"]')
            let $curItem = $container.querySelector('.filter-item[data-value="' + value + '"]')
            checkboxSwitch($curItem);
        } else {
            let $mContainer = document.querySelector('.filter-item[data-head="' + filter + '"][data-value="' + value + '"]')
            if ($mContainer) {
                console.log('UNMARK_MOBILE')
                checkboxCustomSwitch($mContainer)
            }

            if ($container.dataset.type == 'select') {
                let $filterOptions = $container.querySelectorAll('.filter-item')
                refreshFocus($filterOptions, null, filterOptionActiveClass)
            }
        }
    }

}


function addChipsDom(text, key, id) {
    return '<div class="filter-chips__item">\n' +
        '                        <div class="filter-chips__item-text">' + text + '</div>\n' +
        '                        <div  data-id="' + id + '" data-key="' + key + '" class="filter-chips__item-icon">\n' +
        '                            <svg>\n' +
        '                                <use xlink:href="/icons/icons-sprite.svg#close"></use>\n' +
        '                            </svg>\n' +
        '                        </div>\n' +
        '                    </div>'
}

